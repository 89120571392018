import Vue from "vue";
import ProcessAreaService from "@/services/process-area";

function initialState() {
  return {
    isReady: false,
    process_areas: null
  };
}

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    INITIALIZE(state, payload) {
      Object.keys(initialState()).forEach((key) => {
        if (key in (payload || {})) {
          state[key] = payload[key];
        }
      });
    },
    RESET(state) {
      const s = initialState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
    SET_PROCESS_AREAS(state, option) {
      Vue.set(state, "process_areas", option);
    },
    IS_READY(state, option) {
      state.isReady = option;
    }
  },
  actions: {
    reset({state, commit, rootState}) {
      commit("RESET");
    },
    fetchProcessAreas({state, commit, rootState}, force) {
      if (
        !(
          rootState.user &&
          rootState.user.loggedUser &&
          "contract_id" in rootState.user.loggedUser
        ) ||
        (!force && state.process_areas instanceof Array)
      )
        return;
      let query = {
        contract_id: rootState.user.loggedUser.contract_id
      };
      commit("IS_READY", false);
      let srv = new ProcessAreaService();
      return srv.fetchAll(query).then((data) => {
        if (data && data.length) {
          commit("SET_PROCESS_AREAS", data);
        }
        commit("IS_READY", true);
      });
    },
    save(context, payload) {
      return new Promise((resolve, reject) => {
        if (!payload) return;
        let srv = new ProcessAreaService();
        context.commit("IS_READY", false);
        srv.save(payload).then(
          (data) => {
            let lst = context.state.process_areas.filter(
              ({id}) => id != data.id
            );
            lst.push(data);
            context.commit("SET_PROCESS_AREAS", lst);
            context.commit("IS_READY", true);
            resolve(data);
          },
          (err) => {
            context.commit("IS_READY", true);
            reject(err);
          }
        );
      });
    },
    remove(context, payload) {
      if (!payload) return;
      let srv = new ProcessAreaService();
      context.commit("IS_READY", false);
      srv.remove(payload).then(() => {
        let lst = context.state.process_areas.filter(
          ({id}) => id != payload.id
        );
        context.commit("SET_PROCESS_AREAS", lst);
        context.commit("IS_READY", true);
      });
    }
  },
  getters: {
    isReady(state) {
      return state.isReady;
    },
    processAreas(state) {
      return state.process_areas ?? [];
    }
  }
};
